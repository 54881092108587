import { useState, useEffect } from 'react';
import './App.css';
import { firebaseDB } from './firebase'
import { driverTeams } from './components/DriverTeams'
import driveToSpinLogo from './components/Images/Logo/DTSLogo.png'
import ChampionshipPoints from './components/ChampionshipPoints'
import DriverValues from './components/DriverValues'
import TrackList from './components/TrackList'


const App = () => {
  const [rounds, setRounds] = useState([]);
  const [roundSelector, setRoundSelector] = useState([])
  const [roundSelected, setRoundSelected] = useState(false)
  const [raceResults, setRaceResults] = useState([]);
  const [qualiResults, setQualiResults] = useState([]);
  const [fastestLapTime, setFastestLapTime] = useState()
  const [showGPResults, setShowGPResults] = useState(true)
  const [showRoundSelector, setShowRoundSelector] = useState(false)
  const [trackList, setTrackList] = useState([])
  const [roundValues, setRoundValues] = useState([]);
  const [finalResults, setFinalResults] = useState([]);
  const [leagueSelector, setLeagueSelector] = useState([])
  const [seasonName, setSeasonName] = useState('')

  useEffect(() => {
    
    // console.log('raceResults = ', raceResults)
    // console.log('qualiResults = ', qualiResults)
    // console.log('fastestLapTime = ', fastestLapTime)
    // console.log('rounds = ', rounds)
    // console.log('roundSelector = ', roundSelector)
    // console.log('trackList = ', trackList)
    //console.log('finalResults = ', finalResults)
    //console.log('roundValues = ', roundValues)

    if (trackList.length < 1) {

      //firebaseDB.ref(`leagues/driveToSpin2023`)
      firebaseDB.ref(`leagues`)
      .once('value')
      .then(data => {
        //console.log('data.val() = ', data.val())

        var leagues = []

        leagues = Object.keys(data.val())

        leagues.pop()

        var lastLeague = leagues[leagues.length-1]

        //console.log('leagues = ', leagues)
        //console.log('lastLeague = ', lastLeague)

        setLeagueSelector(leagues)

        const seasonNameIs = data.val()[lastLeague].leagueName

        setSeasonName(data.val()[lastLeague].leagueName)

        return firebaseDB.ref(`leagues/${lastLeague}`).once('value')

      })
      .then(data => {
        if (rounds.length === 0 && data.val()) {

          //console.log('driveToSpin2023.data.val() = ', data.val())

          const tempRounds = Object.values(data.val().rounds)
          //console.log('tempRounds = ', tempRounds)
          //console.log('keys = ', Object.keys(data.val().rounds))
          //console.log('trackList = ', data.val().trackList)
    
          setRoundSelector(Object.keys(data.val().rounds))

          setTrackList(Object.values(data.val().trackList))

          //setRoundValues(data.val().driverValues)

          const lastRace = tempRounds[tempRounds.length - 1]

          setRoundSelected(tempRounds.length - 1)

          setRounds(tempRounds)

          setRaceResults(lastRace.race)

          setQualiResults(lastRace.qualifying)

          //const min = lastRace.race.reduce((a, b) => Math.min(a.fastestLap, b.fastestLap))
          //console.log('fastestLap = ', min)
          const closest = lastRace.race.reduce(
            (acc, loc) =>
              acc.fastestLap < loc.fastestLap
                ? acc
                : loc
          )
          setFastestLapTime(closest.fastestLap)

          //return firebaseDB.ref(`leagues/driveToSpin2023Preseason/rounds/6`).update(missingRound)
        }

      })
      .catch(error =>{
        console.log('error = ', error)
      })

      firebaseDB.ref(`leagues/finalResults/2022`)
      .once('value')
      .then(data => {
        var sortedArray = data.val().sort((a,b) => b.value - a.value)       
        setFinalResults(sortedArray)
      })
      .catch(error =>{
        console.log('error = ', error)
      })

      firebaseDB.ref(`leagues/driveToSpin2023/driverValues`)
      .once('value')
      .then(data => {
        setRoundValues(data.val())
      })
      .catch(error =>{
        console.log('error = ', error)
      })

    }
  })

  const bestLapTime = (lapTime) => {
    const minutes = Math.floor(lapTime / 60000);
    const seconds = ((lapTime - minutes * 60000)/1000).toFixed(3);

    var fontColor

    if (lapTime === fastestLapTime) {
      fontColor = 'purple-text'
    }

    var secondsText = seconds

    if ( seconds < 10 ) {
      secondsText = `0${seconds}`
    }

    return(
      <div className={`best-time-container ${fontColor}`}>
        {`${minutes}:${secondsText}`}
      </div>
    )
  }

  const showGridPosition = (gridPosition, isTitle) => {
    if (showGPResults && isTitle) {
      return (
        <div className='grid-container'>
          GRID
        </div>
      )
    } else if (showGPResults && !isTitle) {
      return (
        <div className='grid-container'>
          {gridPosition}
        </div>
      )
    }
  }

  const showPitStops = (numPitStops, isTitle) => {
    if (showGPResults && isTitle) {
      return (
        <div className='stops-container'>
          STOPS
        </div>
      )
    } else if (showGPResults && !isTitle) {
      return (
        <div className='stops-container'>
          {numPitStops}
        </div>
      )
    }
  }

  const checkResultsStatus = (resultStatus) => {
    switch (resultStatus) {
      case 4:
        //Did Not Finish
        return 'DNF'
      case 7:
        //Retired
        return 'DNF'
      case 5:
        //Disqualified
        return 'DQ'
      default:
        return false
    }
  }

  const totalTimeConveter = (time, postion, resultStatus, lapsCompleted) => {

    const p1LapsCompleted = raceResults[0].lapsCompleted

    const driverResultStatus = checkResultsStatus(resultStatus)

    if (postion === 1) {
      const minutes = Math.floor(time / 60);
      const seconds = (time - minutes * 60).toFixed(3);
      var finalSeconds = seconds
      if (seconds < 10) {
        finalSeconds = `0${seconds}`
      }
      return `${minutes}:${finalSeconds}`
    } else if (driverResultStatus) {
      return `${driverResultStatus}`
    } else if (p1LapsCompleted !== lapsCompleted) {
      const lapDelta = p1LapsCompleted - lapsCompleted
      var lapText = 'Lap'
      if (lapDelta > 1) {
        lapText = 'Laps'
      }
      return `+ ${lapDelta} ${lapText}`
    } else {
      const totalTime = (time - raceResults[0].totalRaceTime).toFixed(3)

      var timeToShow = totalTime

      if (totalTime > 60) {
        const mins = Math.floor(totalTime / 60)

        var seconds = (totalTime - (mins * 60)).toFixed(3)

        if (seconds < 10) {
          seconds = `0${seconds}`
        }

        timeToShow = `${mins}:${seconds}`
      }

      return `+${timeToShow}`
    }
  }

  const qualiLapTimeConveter = (lapTime, index, resultStatus) => {
    if (index === 0) {
      return '-'
    }

    const driverResultStatus = checkResultsStatus(resultStatus)

    if (driverResultStatus) {
      return `${driverResultStatus}`
    }

    if (index > 0) {
      //const previousDriver = index - 1
      const time = ((lapTime - qualiResults[0].fastestLap)/1000).toFixed(3)
      return `+${time}`
    }
  }

  const showRaceResults = () => {
    var data = raceResults
    if (showGPResults === false) {
      data = qualiResults
    }

    return(
      <div className='result-tables-conatiner'>
        <div className='results-row row-heading-text'>
          <div className='postion-container'>
            POS
          </div>
          <div className='name-container'>
            DRIVER
          </div>

          <div className='team-name-container'>
            TEAM
          </div>

          {showGridPosition(0, true)}

          {showPitStops(1, true)}

          <div className={`best-time-container`}>
            BEST
          </div>

          {showGPResults ?
            <div className='best-time-container'>
              TIME
            </div>
            :
            <div className='best-time-container'>
              GAP
            </div>
          }
        </div>
        {
          data.map((result, index) =>{
            const { name, position, gridPosition, numPitStops, fastestLap, totalRaceTime, resultStatus, lapsCompleted } = result
            return(
              <div key={index} className='results-row grey-background white-text'>
                <div className='postion-container'>
                  {position}
                </div>
                <div className='name-container'>
                  {name}
                </div>

                <div className='team-name-container'>
                  {driverTeams(name)}
                </div>

                {showGridPosition(gridPosition, false)}

                {showPitStops(numPitStops, false)}

                {bestLapTime(fastestLap)}

                {showGPResults ?
                  <div className='best-time-container'>
                    {`${totalTimeConveter(totalRaceTime, position, resultStatus, lapsCompleted)}`}
                  </div>
                  :
                  <div className='best-time-container'>
                    {`${qualiLapTimeConveter(fastestLap, index, resultStatus)}`}
                  </div>
                }

              </div>
            )
          })
        }

        <ChampionshipPoints
          rounds={rounds}
        />
      </div>
    )
  }

  const roundSelectorItem = (index) => {
    return (
      <button
        className={'round-selector-item'}
        key={index}
        onClick={() =>{
          setShowRoundSelector(false)
          setRaceResults(rounds[index].race)
          setQualiResults(rounds[index].qualifying)
          setRoundSelected(index)
        }}
      >
        {`R${roundSelector[index]}`}
      </button>
    )
  }

  const roundsList = () => {
    return roundSelector.map((round, index) =>{
      return roundSelectorItem(index)
    })
  }

  const roundSelectorList = () => {
    return (
      <div className={'round-selector-container'}>
        <button className={'round-selector-header'}
          onClick={() => setShowRoundSelector(!showRoundSelector)}
        >
          Select Round
        </button>
        <div className={showRoundSelector ? 'show-round-list' : 'hide-round-list'}>
          {roundSelector.length > 0 ?
            roundsList()
            :
            <div></div>
          }
        </div>
      </div>
    )
  }

  const showResultsSelector = () => {
    if (raceResults.length > 0 && qualiResults.length > 0) {
      return(
        <div className='results-selector-container'>
          <div className='selector-container'>
            <img
              src={driveToSpinLogo}
              alt={'DTS Logo'}
            />

            {roundSelectorList()}

            <button onClick={() => setShowGPResults(true)}>
              GP
            </button>
            <button onClick={() => setShowGPResults(false)}>
              Qualifying
            </button>
          </div>
        </div>
      )
    }
  }

  const showSeasonTitle = () => {
    if (trackList.length > 0) {
      return (
        <div className='track-title'>
          <h2>{`${seasonName}`}</h2>
        </div>
      )
    }
  }

  const showTrackTitle = () => {
    if (trackList.length > 0) {
      return (
        <div className='track-title'>
          <h2>{`${trackList[roundSelected]}`}</h2>
          <h3>{showGPResults ? 'Race Results' : 'Qualifying Results'}</h3>
        </div>
      )
    }
  }

  const showDriverValues = () => {

    if (roundValues.length > 0 && finalResults.length > 0) 
    return (
      <DriverValues 
        roundValues={roundValues}
        finalResults={finalResults}
      />
    )
  }

  const showTrackList = () => {

    return (
      <TrackList 
        trackList={trackList}
        rounds={rounds}
      />
    )
  }

  return (
    <div className="App">
      <div className='round-results-container'>
        {showResultsSelector()}
        {showSeasonTitle()}
        {showTrackTitle()}
        {raceResults.length > 0 ?
          showRaceResults()
          :
          <div></div>
        }
        {showTrackList()}
        {showDriverValues()}
      </div>
    </div>
  );
}

export default App;
